<template>
  <div id="cast-point-history">
    <card-header ref="header" :node="header" />
    <div id="content-scroll" class="content-scroll">
      <div
        class="content d-flex flex-column align-items-center"
        v-if="user_bd_points.user_bd_points"
      >
        <div class="content__points-label f-w3">コイン残高</div>
        <div class="content__points-value d-flex f-w6">
          <img
            src="@/assets/image/order_icons/coin.svg"
            alt="coin"
            class="coin-item"
          />
          <div>
            {{
              parseInt(user_bd_points.deposit_points) > 0
                ? Intl.NumberFormat()
                    .format(user_bd_points.deposit_points)
                    .replace(/\./g, ",")
                : 0
            }}<span class="coin-text">コイン</span>
          </div>
        </div>
        <div class="content__points-des f-w3">
          コイン有効期限は150日になります
        </div>
        <div class="content__Withdrawal f-w6" @click="checkIsBankAcc">
          <img src="@/assets/image/icon-refresh.svg" alt="" />
          出金申請
        </div>
        <div class="content__history">
          <div class="content__history__tab d-flex">
            <div
              class="f-w6"
              :class="{ actived: type == 1 }"
              @click="nextTab(1)"
            >
              獲得
            </div>
            <div
              class="f-w6"
              :class="{ actived: type == 2 }"
              @click="nextTab(2)"
            >
              申請
            </div>
          </div>
          <div
            class="content__history__wrap"
            :class="[user.bank_name ? '' : 'content__history__wrap--bank']"
            v-if="user_bd_points.user_bd_points.total > 0"
          >
            <div
              class="content__history__wrap__item"
              v-for="(item, index) in user_bd_points.user_bd_points
                .user_bd_points"
              :key="index"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="content__history__wrap__item__time f-w3">
                  {{ $dayjs(item.transacted_at).format("YYYY/MM/DD HH:mm") }}
                </div>
              </div>
              <div
                class="content__history__wrap__item__reason f-w3 text-left d-flex justify-content-between"
              >
                {{ item.reason }}
                <div
                  class="f-w6"
                  :class="[
                    parseInt(item.points) >= 0
                      ? 'content__history__wrap__item__points'
                      : 'content__history__wrap__item__point-change--minus'
                  ]"
                >
                  {{
                    parseInt(item.points) > 0
                      ? "+" +
                        Intl.NumberFormat()
                          .format(item.points)
                          .replace(/\./g, ",")
                      : Intl.NumberFormat()
                          .format(item.points)
                          .replace(/\./g, ",")
                  }}コイン
                </div>
              </div>
            </div>
            <b-pagination
              v-if="user_bd_points.user_bd_points.total > 12"
              class="pagination f-w6 w-100"
              v-model="currentPage"
              :total-rows="user_bd_points.user_bd_points.total"
              :per-page="perPage"
              first-number
              last-number
            >
              <template slot="prev-text" v-if="currentPage == 1">
                <img src="@/assets/image/left-arrow-pagination.svg" alt="" />
              </template>
              <template slot="prev-text" v-if="currentPage != 1">
                <img
                  src="@/assets/image/right-arrow-pagination.svg"
                  class="left-active-pagination"
                  alt=""
                />
              </template>
              <template
                slot="next-text"
                v-if="
                  currentPage < user_bd_points.user_bd_points.total / perPage
                "
              >
                <img src="@/assets/image/right-arrow-pagination.svg" alt="" />
              </template>
              <template
                slot="next-text"
                v-if="
                  currentPage >= user_bd_points.user_bd_points.total / perPage
                "
              >
                <img
                  src="@/assets/image/left-arrow-pagination.svg"
                  class="right-active-pagination"
                  alt=""
                />
              </template>
            </b-pagination>
          </div>
          <div class="empty" v-if="user_bd_points.user_bd_points.total === 0">
            <img src="@/assets/image/empty_data.svg" alt="" class="" />
            <p class="f-w3">獲得ポイントはありません</p>
          </div>
        </div>
        <div
          v-if="!user.bank_name"
          class="content__registration__wrap d-flex justify-content-center align-items-center f-w3"
          @click="nextRegisterBank"
        >
          <div class="content__registration f-w6">
            <span>銀行口座登録</span>
          </div>
          <!-- <div class="position-absolute filter-blur"></div> -->
        </div>
      </div>
    </div>
    <card-footer ref="footer" />
    <ModalLayout ref="withdraw" :defaultStyle="false">
      <PointHistoryWithdraw :totalPoint="user_bd_points.deposit_points" />
    </ModalLayout>
    <ModalLayout ref="bankAcc" :defaultStyle="false">
      <BankAccRegister />
    </ModalLayout>
  </div>
</template>

<script>
import CardFooter from "@/components/CardFooter.vue";
import CardHeader from "@/components/CardHeader.vue";
import PointHistoryWithdraw from "@/components/PointHistoryWithdraw.vue";
import BankAccRegister from "@/components/BankAccRegister.vue";
import { mapGetters } from "vuex";

export default {
  name: "PointHistory",
  components: {
    "card-header": CardHeader,
    "card-footer": CardFooter,
    PointHistoryWithdraw,
    BankAccRegister
  },
  metaInfo() {
    return {
      title: "コイン履歴",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      header: {
        text: "コイン履歴",
        isShowTitle: true,
        isShowLogo: false,
        back: null
      },
      isShowTab: 1,
      pointBalance: null,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      perPage: this.$route.query.perPage ? this.$route.query.perPage : 12,
      type: this.$route.query.type ? this.$route.query.type : 1
    };
  },
  mounted() {
    this.getPointHistory();
  },
  computed: {
    ...mapGetters({
      user_bd_points: "pointHistory/user_bd_points",
      user: "auth/user"
    })
  },
  watch: {
    currentPage: function() {
      this.$root.$refs.loading.start();
      this.getPointHistory();
      this.$root.$refs.loading.finish();
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (
        from.fullPath == "/withdrawal" ||
        from.name == "BankAccountRegistration"
      ) {
        vm.header.back = "/cast/my-page";
      } else {
        vm.header.back = "/cast/my-page";
      }
    });
  },
  methods: {
    nextTab(index) {
      if (this.type != index) {
        this.type = index;
        if (this.currentPage != 1) {
          this.currentPage = 1;
        }
        this.$root.$refs.loading.start();
        this.getPointHistory();
        this.$root.$refs.loading.finish();
      }
    },
    async getPointHistory() {
      await this.$store.dispatch("pointHistory/getListBdPoint", {
        page: this.currentPage,
        perpage: this.perPage,
        type: this.type
      });
      this.$router
        .push({
          path: "/cast/my-page/bd-history",
          query: {
            currentPage: this.currentPage,
            perPage: this.perPage,
            type: this.type
          }
        })
        .catch(() => {});
    },
    downloadPdf() {
      this.$refs["modal-detail-receiption"].hide();
      this.$refs["modal-pdf-download"].show();
    },
    cancelDownloadPDF() {
      this.$refs["modal-pdf-download"].hide();
    },
    detailPoint() {
      this.$root.$refs.loading.start();
      this.$router.push({ name: "DetailPoint", params: { id: "1" } });
      this.$root.$refs.loading.finish();
    },
    nextPageRegisterBank() {
      localStorage.setItem("nameRoute", "CastBdHistory");
      this.$router.push({ name: "BankAccountRegistration" });
    },
    async checkIsBankAcc() {
      if (this.user.bank_name) {
        await this.$refs.withdraw.openModal();
        let inputMoney = document.querySelector(".input-money");
        if (inputMoney) {
          inputMoney.focus();
        }
      } else {
        await this.$refs.bankAcc.openModal();
      }
    },
    nextRegisterBank() {
      this.$root.$refs.loading.start();
      localStorage.setItem("nameRoute", "CastBdHistory");
      let genderRouter = "CastMyPage";
      if (this.user.sex === 1) {
        genderRouter = "MenMyPage";
      }
      this.$router.push({ name: genderRouter + "BankAccountRegistration" });
      this.$root.$refs.loading.finish();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cast/bdHistory.scss";
@import "@/assets/scss/_fontFamily.scss";
.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 60px;
  overflow-x: hidden;
  position: relative;
  z-index: 0;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   height: calc(100vh - 152px);
  // }
}
</style>
<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  .page-link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    line-height: 19px;
    color: #00416d;
    border: none;
    margin: 0 12px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    a {
      color: #00416d;
    }
    &:hover {
      background-color: #fff;
    }
  }
  .page-item.active .page-link {
    background: #0b2044;
    color: #fff;
    border-radius: 5px;
    width: 29px;
    height: 29px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    padding: 0px;
    margin: 0 3px;
  }
  .left-active-pagination,
  .right-active-pagination {
    transform: rotate(180deg);
  }
}
</style>
